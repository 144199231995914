import { message } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import config from "../config";
import KC from "@App/@types/keycloakTypes";


const backendUrl = ( config.backendUrl ?? '') + '/api';


/**
 * Retrieves the value of a specified cookie from the document's cookie string.
 *
 * @param {string} name - The name of the cookie whose value should be retrieved.
 * @return {string | undefined} - The value of the cookie if found; undefined otherwise.
 */
function getCookie(name: string) {
  // Prefix the document's cookie string with "; " to ensure consistent parsing.
  const value = `; ${document.cookie}`;
  // console.log('value of the cookie token: ',value)

  // Split the cookie string into an array based on the target cookie's name.
  // The split function creates a new array where each element is a portion of the string
  // that falls between instances of the separator string (`; ${name}=` in this case).
  const parts = value.split(`; ${name}=`);

  // If the cookie is present, there should be two parts in the array after the split.
  if (parts.length === 2) {
    // Pop removes and returns the last element in the array.
    // Here, it's used to isolate the portion of the string that contains the cookie's value.
    const cookieValue = parts.pop();
    // console.log('cookie TOKEN: ', cookieValue?.split(';').shift())

    // If cookieValue is not undefined, further split it on ";" and return the first part, which is the actual value.
    // If cookieValue is undefined, return undefined.
    return cookieValue ? cookieValue.split(';').shift() : undefined;
  }

  // If the cookie isn't found (i.e., there weren't two parts after the initial split), return undefined.
  return undefined;
}


/**
 * Main API handler func to communicate with backend.
 * @param request a request config and payload object
 * @param kc a KeyCloak instance (optional)
 * @returns a Promise<T>, API response data that should match 'T' type
 */
export function handler<T>( request: API.Request, kc: KC.KCType | KC.KCDefault): Promise<T> {
  // Set default request headers
  const defaultHeaders = {
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*'
  };
  
  // Set current request method
  const method: RequestInit['method'] = request.method;

  // Add token to headers if it is present in the request object
  const headers: RequestInit['headers'] = new Headers(defaultHeaders);

  // Use the auth instance to get a new access token if the current one is expired
  const checkTokenValidity = async () => {
    const tokenParsed = kc.tokenParsed!;
    const expiresAt = (tokenParsed?.exp ?? 0) * 1000;
    // const now = new Date().getTime();
    const now = dayjs().valueOf();
    const diff = expiresAt - now;
    // console.log(`token valid for ${dayjs.duration(diff).asSeconds()} seconds.`);
    if (dayjs.duration(diff).asSeconds() < 12) {
      // Token has expired preform refresh
      await kc.updateToken(15)
        // .then( (success) => {
        //   if (!success) {
        //     message.error('Failed refresh token. Please try again later.');
        //   }
        // })
    }

    return kc.token!;
  };

  // Fetch the data
  const fetchData = async (): Promise<T> => {
// console.info('request:', request );
    let options = { headers, method, credentials: 'include' } as RequestInit;

    // Add the client auth token to the headers
    const token = await checkTokenValidity();
    headers.set('Authorization', `Bearer ${token}`);

    // Add the XSRF token to the headers
    const xsrfToken = getCookie("_xsrf");
    xsrfToken && headers.set('X-XSRFToken', xsrfToken);
    // console.log('xsrfToken', xsrfToken)

    if ( request.method === "PUT" || request.method === "POST" ) {
      const body = JSON.stringify(request.payload) as RequestInit['body'];
      options = { headers, method, body };
// console.info('payload:', payload);
    }

    const response = await fetch( backendUrl + request.path, options );
    const resData = response.json() as Promise<T & Responses.Default>;
    // if (!response.ok) {
    //   resData.then(
    //     ({error}) => message.error(error ?? 'Failed to connect to backend.')
    //   )
    // }
    // else {
    //   message.success('Data fetched successfully!');
    // }
    // return response.json() as Promise<T>;
    return resData;
  };

  // Handle the response
  const handleResponse = async (data: T) => {
    return data;
  };

  // Catch any errors
  const handleError = (error: Error) => {
    throw error;
  };

  return fetchData()
    .then(handleResponse)
    .catch(handleError);
};
