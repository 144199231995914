import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import {
  Button, Space, Tag, Typography, Descriptions, Input,
  Dropdown, Row, Col, Grid
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { ActionTypes } from '@App/settings/reducers';
import { ApplicationState } from '@App/settings/StateManager';
import { capitalize } from '@App/components/helpers';
import { handler } from '@App/settings/ApiHandler';
import AppTable from '@App/components/tables/TableComponent';
import DeleteButton from '@App/components/buttons/DeleteButton';
import EditButton from '@App/components/buttons/EditButton';

import { DataContext } from '../../AdminFormView';
import KC from "@App/@types/keycloakTypes";
import { useNavigation } from '@App/settings/NavigationProvider';
import { Language } from '@AppRoot';


// // Define Text possible keys
// type TextKeys = 'tooltip_text' | 'helper_text' | 'error_text';
// // Define a TextObject type to ensure that each object in the array has keys from Texts
// type TextsObject = { [K in TextKeys]?: string };
// // Define a TextTuple type to represent the key-value pairs in the 2D array
// type TextsTuple = [TextKeys, string];

// interface FieldType {
//   id: number;
//   type: string;
//   name: string;
//   label: string;
//   settings: {
//     options?: boolean;
//     texts?: TextsObject | TextsObject[] | TextsTuple[];
//   };
// }

const getFields = (kc:KC.KCType) =>
  handler<{ fields: Form.FieldType[] }>({
    path: '/v1/fields',
    method: 'GET',
  },kc);

const onDelete = (record: Form.FieldType) => {
  console.log('Deleting record:', record);
  // Add delete logic here
};

const renderKeyValuePairNew = (key: string, val: string, indexKey?: string) => {
  return (
    <Descriptions.Item key={indexKey ?? key}>
      {val}
    </Descriptions.Item>
  )
}
const renderKeyValuePair = (key: string, val: string, indexKey?: string) => {
  const label = capitalize(key.split('_')[0]);
  return (
    <React.Fragment key={indexKey ?? key}>
      <Typography.Paragraph
        underline
        strong
        type='secondary'
        style={{ marginBottom: 0 }}
      >
        {label}:
      </Typography.Paragraph>
      <Typography.Text>{val}</Typography.Text>
    </React.Fragment>
  );
};
// Update this with your actual types
// type SettingsValue =
//   | boolean
//   | string
//   | Form.FieldSettings['']
//   | TextsObject
//   | TextsObject[]
//   | TextsTuple[];


const tableName = 'manage-fields-list'
export function FieldsList() {
  const [search, setSearch] = React.useState<string>('');
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(20);
  const [searchedData, setSearchedData] = React.useState<API.Field[]>([]);

  const {fields,isLoading, doRefresh} = React.useContext(DataContext);
  const {state,dispatch} = React.useContext(ApplicationState);
  const { handleNavigate: nav } = useNavigation();
  const { t, i18n } = useTranslation();
  const {md} = Grid.useBreakpoint();

  const currentLang = i18n.language as Language;

  // const handleTableChange: TableProps<Responses.TTRData>['onChange'] = (pagination, filters, sorter, extra) => {
  //   // console.log('Various parameters',extra.currentDataSource.length, filters, sorter);
  //   setFilteredInfo(filters);
  //   setResultSize(extra.currentDataSource.length);
  //   setSortedInfo(sorter as SorterResult<Responses.TTRData>)
  // };

  const handleSearch = (value: string) => {
    // setLoading(true);
    const filteredData = fields.filter(d =>
      d.name.toLowerCase().includes(value.toLowerCase()) ||
      d.label.toLowerCase().includes(value) ||
      d.settings.toString().toLowerCase().includes(value.toLowerCase())
    );
    // setResultSize(filteredData.length)
    setSearchedData(filteredData);
    // setLoading(false);
  };
  

  React.useEffect(() => {
    if (!state.tables?.[tableName]) {
      dispatch({ type: ActionTypes.Load_table, payload: { tableName } });
    }
  }, [tableName]);

  React.useEffect(() => {
    if (fields.length) {
      setSearchedData(fields)
    }
  }, [fields]);


  const columns: ColumnsType<Form.FieldType> = [
    {
      key: 'id',
      title: '#',
      // title: <FieldNumberOutlined style={{ fontSize: '1.2rem' }} />,
      dataIndex: 'id',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name)
      },
      width: 40,
      align: 'center',
      className: 'td-no-padding',
    },
    {
      key: 'title',
      title: t('table.column.label.formField'),
      dataIndex: 'label',
      width: 500,
      ellipsis: true,
      sorter: (a, b) => a.label.localeCompare(b.label),
      render: (_, record) => (
        <>
          <Typography.Text strong>{record.label}</Typography.Text>
          <Typography.Paragraph italic type='secondary'>
            {record.name}
          </Typography.Paragraph>
          
        <Descriptions layout={md ? "horizontal" : "vertical"} column={1}>
          <Descriptions.Item label={t('forms.label.fieldType')} >
            <Tag color='processing' title={t(`forms.option.field.${record.type}`)} style={{
              borderColor:'#91caff50'
            }}>
              <i>{record.type.toUpperCase()}</i>
          </Tag>
          </Descriptions.Item>
          {
            record.settings?.options && record.settings?.options.length && (
              <Descriptions.Item label={t('forms.label.fieldOptions')} >
                <Tag color={record.settings?.options.length ? 'green' : undefined}>
                  {record.settings?.options.length ?? '0'}
                </Tag>
              </Descriptions.Item>
            )
          }
          {
            (record.settings?.tooltipTexts?.[currentLang] || record.settings?.tooltipText) && (
              <Descriptions.Item label={t('forms.label.fieldTooltipText')} >
                {record.settings?.tooltipTexts?.[currentLang] ?? record.settings?.tooltipText ?? 'n/a'}
              </Descriptions.Item>
            )
          }
          {
            (record.settings?.placeholderTexts?.[currentLang] || record.settings?.placeholderText) && (
              <Descriptions.Item label={t('forms.label.fieldPlaceholderText')} >
                {record.settings?.placeholderTexts?.[currentLang] ?? record.settings?.placeholderText ?? 'n/a'}
              </Descriptions.Item>
            )
          }
          {
            (record.settings?.helpTexts?.[currentLang] || record.settings?.helpText) && (
              <Descriptions.Item label={t('forms.label.fieldHelpText')} >
                {record.settings?.helpTexts?.[currentLang] ?? record.settings.helpText ?? 'n/a'}
              </Descriptions.Item>
            )
          }
          {
            (record.settings?.errorTexts?.[currentLang] || record.settings?.errorText) && (
              <Descriptions.Item label={t('forms.label.fieldErrorText')} >
                {record.settings?.errorTexts?.[currentLang] ?? record.settings?.errorText ?? 'n/a'}
              </Descriptions.Item>
            )
          }
        
        </Descriptions>

        </>
      ),
    },
    // {
    //   key: 'type',
    //   title: 'Type',
    //   dataIndex: 'type',
    //   // width: 80,
    //   render: (fieldType: string) => (
    //     <Tag color='processing' style={{
    //       borderColor:'#91caff50'
    //     }}>
    //       <i>{fieldType.toUpperCase()}</i>
    //     </Tag>
    //   ),
    // },
    // {
    //   key: 'settings',
    //   title: 'Settings',
    //   dataIndex: 'settings',
    //   // width: 'auto',
    //   width: md ? 200 : 100,
    //   ellipsis: true,
    //   // responsive: ['md'],
    //   render: (settings: Form.FieldSettings, record) => (
    //     <>
    //       <Tag color='processing' style={{
    //         borderColor:'#91caff50'
    //       }}>
    //         <i>{record.type.toUpperCase()}</i>
    //       </Tag>
    //     <Descriptions layout={md ? "horizontal" : "vertical"} column={1}>
    //       {
    //         settings.options && (
    //           <Descriptions.Item label='Options' >
    //             {settings.options.length ?? '0'}
    //           </Descriptions.Item>
    //         )
    //       }
    //       {
    //         settings.placeholderText && (
    //           <Descriptions.Item label='Placeholder text' >
    //             {settings.placeholderText ?? 'n/a'}
    //           </Descriptions.Item>
    //         )
    //       }
    //       {
    //         settings.helper_text && (
    //           <Descriptions.Item label='old helper ? text' >
    //             {settings.helper_text ?? 'n/a'}
    //           </Descriptions.Item>
    //         )
    //       }
    //       {
    //         settings.error_text && (
    //           <Descriptions.Item label='Error text' >
    //             {settings.error_text ?? 'n/a'}
    //           </Descriptions.Item>
    //         )
    //       }
        
    //     </Descriptions>
    //     </>
    //   ),
    // },
    {
      key: 'actions',
      title: 'Actions',
      // width: md ? 120 : 60,
      width: 60,
      // fixed: 'right',
      align: 'end',
      render: (_, record) => (
        <Space size='small' wrap>
          <EditButton
            type='text'
            record={record}
            goTo={`/manage/forms/fields/${record.name ?? record.id}`}
          />
          <DeleteButton
            iconOnly
            type='text'
            item='field'
            path={`/v1/manage/field/`}
            onDelete={onDelete}
            afterDelete={doRefresh}
            record={record}
          />
        </Space>
      ),
    },
  ];

  return (
    <>

      <Row justify='space-between' style={{margin:'1rem 0'}}>
        <Col>
          <Input.Search
            placeholder="Search..."
            // value={search}
            onSearch={handleSearch}
            // prefix={<SearchOutlined />}
          />
        </Col>
        <Col>
          <Button.Group>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              onClick={()=> nav('/manage/forms/fields/new')}
            >
              {t('forms.link.add.field')}
            </Button>
            <Dropdown menu={{items:[]}} trigger={['click']}>
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          </Button.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <AppTable<AppFields.FieldType & {status?: string}>
            rowKey='id'
            tableName={tableName}
            tableTitle={'table.title.fields'}
            extraColumns={columns}
            dataSource={searchedData}
            sticky={{ offsetHeader: 64 }}
            loading={isLoading}
            // size={size}
            // locale={tableTranslations}
            // scroll={{ x: 1024 }}
            // pagination={{
            //   showTotal: (total, range) => t('table.result', { start: range[0], end: range[1], total }),
            //   showSizeChanger: true,
            //   current: currentPage,
            //   pageSize: state.tables?.[tableName]?.pageSize ?? pageSize,
            //   onShowSizeChange: handlePageSizeChange,
            //   onChange: setCurrentPage,
            //   // pageSizeOptions,
            //   className: 'table-pagination',
            // }}
            // rowClassName={compactMode ? ' compact-row ' : ''}
            // expandable={{
            //   rowExpandable: (record) => record.form_data && Object.keys(record.form_data).length > 0,
            //   expandedRowRender: (record) => (
            //     <ExpandRowContent
            //       key={record.uuid}
            //       record={record}
            //       onEdit={props.onAction ?? console.log}
            //       formFields={props.extraColumns}
            //       isAdmin={isAdmin}
            //     />
            //   ),
            //   expandIcon: ({expanded, onExpand, record}) => expanded
            //     ? <DownSquareFilled onClick={(e)=> onExpand(record, e)} style={{fontSize: '1.4rem'}} />
            //     : <RightSquareOutlined onClick={(e)=> onExpand(record, e)} style={{fontSize: '1.4rem'}} />
            // }}
            // onChange={handleTableChange}
            style={{ maxWidth: '100%' }}
            isAdmin={true}
            isSelectable={false}
          />
        </Col>
      </Row>

    </>
  );
}
