import { Form, FormProps, Input, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { handler } from '@App/settings/ApiHandler';
import { useKeycloak } from '@App/settings/keycloak';
import { DataContext } from '@App/views/AccessView';
import KC from "@App/@types/keycloakTypes";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

type PermissionType = {
  id?: string;
  name: string;
  label: string;
  role: string;
};

const getPermissionData = (kc: KC.KCType, id: string|number) => handler<PermissionType>({
  method: 'GET', path: '/v2/manage/permission/' + id,
}, kc);

const postNewPermission = (kc: KC.KCType, payload: API.PayloadType) => handler<PermissionType>({
  method: 'POST', path: '/v2/manage/permission/new', payload
}, kc);
const updateNewPermission = (kc: KC.KCType, payload: API.PayloadType, id:string) => handler<PermissionType>({
  method: 'POST', path: '/v2/manage/permission/'+id, payload
}, kc);

const defaultValues = { name: '', label: '', role: '' };

type PermissionFormProps = FormProps & {
  action: 'new' | 'update';
}

export const PermissionForm: React.FC<PermissionFormProps> = ({action, ...props}) => {
  const {permissions} = React.useContext(DataContext);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [permission, setPermission] = React.useState<PermissionType>(defaultValues);
  const { id } = useParams();
  const [form] = Form.useForm();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const oldValues = React.useRef<PermissionType|null>(null);
  const isMounted = React.useRef(false);

  const getData = () => {
    setIsLoading(true);
    
    id && getPermissionData(keycloak!, id)
      .then( (data) => {
        setPermission(data);
        oldValues.current = data;
        form.setFieldsValue(data);
      })
      .finally( () => {
        setIsLoading(false)
      });
  };
  
  const onFinish = (values: PermissionType) => {
    form.validateFields().then( () => {

      if (action == 'new') {
        postNewPermission(keycloak!, { action, ...values })
      }
      else {
        updateNewPermission(keycloak!, { action, ...values }, id!)
      }
    })
  };

  const onReset = () => {};
  const onDelete = () => {};

  const isNameUnique = (name: string): boolean => {
    return !permissions.some(permission => permission.name === name);
  };
  
  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      if ( id && !['new','add','create'].includes(id) ) {
        getData();
      }
    }

    return () => {
      console.info('unmounted');
    };
  }, []);
  
  return (
    <div>

      <Spin spinning={isLoading}>
        <Form
          {...layout}
          name={action === 'new' ? 'Add new permission' : 'Update permission'}
          layout='horizontal'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 18 }}
          onFinish={onFinish}
          // style={{ maxWidth: 600 }}
      
          {...props}
        >
          <Form.Item
            name='key'
            label={t('forms.label.name')}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || isNameUnique(getFieldValue('name'))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Name already exists!'));
                  }
              })
            ]}>
            <Input />
          </Form.Item>
          
          <Form.Item name='label' label={t('forms.label.displayedName')} rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name='role' label={t('forms.label.role')} rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            name='description'
            label={t('forms.label.description')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item {...tailLayout}>
            <Space.Compact block>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
              <Button htmlType='button' onClick={onReset}>
                Reset
              </Button>
              {
                action === 'update' && (
                  <Button danger htmlType='button' onClick={onDelete}>
                    delete
                  </Button>
                )
              }
            </Space.Compact>
          </Form.Item> */}

        </Form>
      </Spin>

    </div>
  );
};
