import React from 'react';
import { Button, ButtonProps, Grid, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { handler } from '@App/settings/ApiHandler';
import { useKeycloak } from '@App/settings/keycloak';
import { useTranslation } from 'react-i18next';
import KC from "@App/@types/keycloakTypes";
import { message } from 'antd';

const apiPath = '/v1';

interface DeleteButtonProps {
  path: string;
  item: string;
  record: any;
  iconOnly?: boolean;
  onDelete?: (record: any) => void;
  afterDelete?: () => void;
}

type DeleteResponse = {
  success?: string;
  error?: string;
  message?: string;
  forms?: string[];
  errorCount?: number;
}

const handleDeleteRequest = (path: string, id: string, kc: KC.KCType) => handler<DeleteResponse>({
  path: path,
  method: 'PUT',
  payload: { action: 'delete', id }
}, kc);

const DeleteButton: React.FC<DeleteButtonProps & ButtonProps> = (props) => {
  const {
    path,
    record,
    onDelete,
    afterDelete,
    type,
    iconOnly = false,
    ...rest
  } = props;
  const [open, setOpen] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const { md } = Grid.useBreakpoint();
  // Translate the item first
  const translatedItem = t('forms.actions.' + props.item);

  // Construct the translation key
  const confirmText = t('forms.actions.confirm.delete', { item: translatedItem });

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    handleDeleteRequest(path, record.id, keycloak!)
      .then((res: DeleteResponse) => {
        if (res.success) {
          //@ts-ignore
          message.success(t(`forms.success.${res.success}`, res.message));
          onDelete && onDelete(res || record);
          afterDelete && afterDelete();
        } else if (res.error) {
          //@ts-ignore
          message.error(t(`forms.error.${res.error}`, res.message));
          res.forms && message.error(t(`forms.error.${res.message}`, { forms: res.forms.join(', '), count: res.forms.length }));
          res.errorCount && message.error(t(`forms.error.${res.message}`, { count: res.errorCount }));
        }


      }).then((err) => {
        console.log('Error: ', err)
      }).finally(() => {
        setConfirmLoading(false);
        setOpen(false);
      })
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Popconfirm
      title={confirmText}
      okText={t('forms.label.yes')}
      cancelText={t('forms.label.no')}
      // description="Confirm modal body text goes here."
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
      <Button
        danger
        type={type}
        icon={<DeleteOutlined />}
        onClick={showPopconfirm}
        {...rest}
      >
        {!iconOnly && (md && t('forms.btn.delete'))}
      </Button>
    </Popconfirm>
  );
};

export default DeleteButton;