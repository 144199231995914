import {
  CheckOutlined, CloseOutlined, FilterFilled, ClearOutlined,
  ReloadOutlined, PlusOutlined, DownSquareFilled,
  RightSquareOutlined, FilterTwoTone, FilterOutlined, SearchOutlined, UserDeleteOutlined,
} from '@ant-design/icons';
import {
  App, Col, Divider, Modal, Row, Typography, Checkbox, Input, Tag, Space,
  Radio, Select, Drawer, Flex, Tooltip, Button, Collapse, DatePicker, Badge, theme, List
} from 'antd';
import { ColumnsType, ColumnType, SorterResult } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

import { useKeycloak } from '@App/settings/keycloak';
import { handler } from '@App/settings/ApiHandler';
import { AdminDataUpdate } from '@App/components/forms/AdminDataUpdate';
import { ApplicationState } from '@App/settings/StateManager';
import { ResearchExpandRowContent } from '@App/components/tables/ResearchExpandableRow';
import { renderValue } from '@App/components/tables/TableHelpers';
import { formatDate, formatDateValue } from '@App/components/helpers';
import { statusItems } from '@App/components/tables/TableTitleComponent';
import { ActionTypes } from '@App/settings/reducers';
import AppTable from '@App/components/tables/TableComponent';
import DynamicFormModal from '@App/components/forms/DynamicFormModal';
import PageWrapper from '@App/components/wrappers/PageWrapper';
import UserSearchModalPublic, { LdapUserInfo } from "@App/components/UserSearchModalPublic";
import KC from "@App/@types/keycloakTypes";
import { MenuItemType } from "antd/es/menu/interface";
import { wrapWithTooltip } from "@App/components/wrapWithTooltip";
import { useNavigation } from "@App/settings/NavigationProvider";
import { Language } from '@AppRoot';


const tableName = 'adminResearchProjectTable';

type Res = {
  success?: string;
  error?: string;
  faculties: Responses.FacultyType[] | API.FacultyItem[];
  forms: Form.FormType[];
  items: Responses.ResearchData[];
  all?: Responses.ResearchData[];
}

const getResearchProjects = (kc: KC.KCType) => handler<Res>({
  method: 'GET',
  path: '/v3/researchProjects/',
}, kc!)

const postResearchProjects = (
  kc: KC.KCType,
  payload: API.PayloadType,
  path: string = '/v1/researchProjects/',
) => handler<{ success?: string, error?: string }>({
  method: 'PUT', path, payload
}, kc!);

const postAdminResearchProjects = (
  kc: KC.KCType,
  payload: API.PayloadType,
  path: string = '/v1/researchProjects/',
) => handler<{ success?: string, error?: string }>({
  method: 'POST', path, payload
}, kc!);

export const AdminResearchProjects: React.FC = () => {
  const [action, setAction] = React.useState<API.ActionType>('new');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [filtersOpen, setFiltersOpen] = React.useState<boolean>(false);
  const [adminModalOpen, setAdminModalOpen] = React.useState<boolean>(false);

  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>([])

  const [fFields, setFFields] = React.useState<Form.FormType['fields'] | API.Form['fields']>([]);

  const [researchData, setResearchData] = React.useState<Responses.ResearchData[]>([]);
  const [faculties, setFaculties] = React.useState<Array<Responses.FacultyType | API.FacultyItem>>([]);
  const [formsData, setFormsData] = React.useState<Array<Form.FormType | API.Form>>([]);

  const selectedForm = React.useRef<Form.FormType>();
  const selectedItem = React.useRef<Responses.ResearchData>();

  const { keycloak } = useKeycloak();
  const { t, i18n } = useTranslation();
  const { token } = theme.useToken();
  const { message, notification } = App.useApp();
  const { handleNavigate: nav } = useNavigation();

  const [search, setSearch] = React.useState<string>('');
  const [searchedData, setSearchedData] = React.useState<Responses.ResearchData[]>([]);

  const { state, dispatch } = React.useContext(ApplicationState);

  const [resultSize, setResultSize] = React.useState<number>(0);
  const [visibleColumns, setVisibleColumns] = React.useState<Array<string>>([]);
  const [lockedColumns, setLockedColumns] = React.useState<{ [key: string]: 'left' | 'right' | undefined }>({});

  const [showLdapUserSearch, setShowLdapUserSearch] = React.useState<boolean>(false);
  const [sharedWithUsers, setSharedWithUsers] = React.useState<API.UserMeta[]>([]);

  const [sortedInfo, setSortedInfo] = React.useState<SorterResult<Responses.ResearchData>>({});
  const compactMode = state.tables?.[tableName]?.compactMode;

  const get = () => {
    const abortController = new AbortController();
    setIsLoading(true);

    getResearchProjects(keycloak!)
      .then((data) => {
        if (data.forms) {
          setFormsData(data.forms);
        }

        if (data.all) {
          setResearchData(data.all);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      abortController.abort();
    };
  };

  const handleCancel = () => {
    setModalOpen(false);
    setAdminModalOpen(false);
  };

  const handleDataUpdate = (item: Responses.ResearchData) => {
    const selectedDataValues = researchData.find(i => i.uuid == item.uuid);

    if (selectedDataValues) {
      selectedItem.current = selectedDataValues;

      if (Object.keys(selectedDataValues.form_data).some(i => i.includes('duration'))) {
        let rangeValue: [Dayjs | string | null, Dayjs | string | null] = [null, null]
        const val = selectedDataValues.form_data['research_duration'];
        if (typeof val == 'string') {
          rangeValue = [null, null]
        }
        else if (Array.isArray(val)) {
          rangeValue = [dayjs(val[0]), dayjs(val[1])]
        }
        selectedItem.current = {
          ...selectedDataValues,
          form_data: {
            ...selectedDataValues.form_data,
            //@ts-ignore
            research_duration: rangeValue
          }
        };
        setSharedWithUsers(selectedDataValues.shared_with_users);
      }
      setAction('update');
      setModalOpen(true);
    }
  };

  const handleEntityPost = (values: any) => {
    const payload = { action, payload: values };
    const path = `/v3/manage/researchProjects/${selectedItem.current?.uuid}`;
    postAdminResearchProjects(keycloak!, payload, path)
      .then((res) => {
        if (res.success) {
          message.success(t(`forms.success.${res.success}`))
        }
        setAdminModalOpen(false);
        get();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleResearchAdminChange = (item: Responses.ResearchData) => {
    const selectedDataValues = researchData.find(i => i.uuid == item.uuid);
    if (selectedDataValues) {
      selectedItem.current = selectedDataValues;
      setAction('update');
      setAdminModalOpen(true);
    } else {
      message.warning('Data entity was not found.')
    }
  };

  const handleDataDelete = (e?: any) => {
    console.log(e);
    message.success(t(e.success))
    setAction('delete');
    setModalOpen(false);
    get();
  };

  const moreMenuItems = [{
    key: "refresh",
    icon: <ReloadOutlined />,
    label: t('buttons.refresh'),
    onClick: () => get()
  }, {
    key: "new",
    icon: <PlusOutlined />,
    label: t('links.new.data'),
    onClick: () => nav('/ttr/new'),
  }];

  const handleFailSubmission = (values: object) => {
    message.warning(t('forms.error.checkFields', 'Palun kontrolli kohustuslike välju.'))
  };

  const handleResearchItemUpdate = (values: object) => {
    setIsLoading(true);
    const payload = {
      form_id: selectedForm.current?.id,
      form_uuid: selectedForm.current?.uuid ?? selectedItem.current?.form_uid,
      form_slug: selectedForm.current?.slug ?? selectedItem.current?.form_uid,
      uuid: selectedItem.current?.uuid,
      sharedWith: sharedWithUsers,
      form_data: values
    }

    postResearchProjects(keycloak!, {
      action, payload,
    }, `/v3/researchProjects/${selectedItem.current?.uuid}`)
      .then((res) => {
        // console.log(res)
        if (res.success) {
          //@ts-ignore
          notification.success(t('forms.success.' + res.success))
        }
        setModalOpen(false);
        get();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleColumnToggleOld = (e: any, columnName: string) => {
    setHiddenColumns(prev => {
      if (e.target.checked) {
        return [...prev, columnName];
      } else {
        return prev.filter(name => name !== columnName);
      }
    });
    // updateTableConfig({
    //   ...state.tables[tableName]?, hiddenColumns
    // })
  };

  const handleColumnToggle = (columnName: string) => {
    if (hiddenColumns.includes(columnName)) {
      setHiddenColumns((prev) => prev.filter((name) => name !== columnName));
    } else {
      setHiddenColumns((prev) => [...prev, columnName]);
    }
  };

  const menuItems: MenuItemType[] = fFields.map((field) => ({
    key: field.name,
    title: field.label,
    label:
      <Checkbox
        checked={hiddenColumns?.includes(field.name)}
        onChange={() => handleColumnToggle(field.name)}
      >
        {field.label}
      </Checkbox>
  }));

  const colWidths: {small: number, medium: number, large:number} = {
    small: 150,
    medium: 200,
    large: 320
  }

  const getColumnWidth = (field: API.Field, columnTitle: string) => {
    const colWidth = field.settings?.columnWidth;
    if (colWidth) {
      return colWidths[colWidth];
    }
    return columnTitle.length > 20 ? 340 : 140;
  }

  const formatColumns = (list: Array<API.Field>): Array<ColumnType<Responses.ResearchData>> => list.map((field) => {
    const columnTitle = t(`table.column.label.${field.name}`,
      field.settings?.labelTexts?.[i18n.language as Language] ?? field.settings.placeholderTexts?.[i18n.language as Language] ?? t(`forms.label.${field.name}`, field.name))
    let filterProps: ColumnType<Responses.ResearchData> = {
      key: field.name,
      // title: wrapWithTooltip({
      //   translationKey: <>translation key: {`"forms.label.${field.name}"`}<br />fallback label: {JSON.stringify(field.label)}</>,
      //   children: <>{t(`forms.label.${field.name}`, field.label)}</>,
      // }),
      title: wrapWithTooltip({
        translationKey: (
          <Typography>
            <Typography.Paragraph style={{color: "white"}}>
              <Typography.Text style={{color: "white", opacity: 0.5}} type={'secondary'}>{t('table.column.label.translationKeys')} </Typography.Text>
              {`"table.column.label.${field.name}"`}
            </Typography.Paragraph>
            <Typography.Paragraph style={{color: "white"}}>
              <Typography.Text style={{color: "white", opacity: 0.5}} type={'secondary'}>{t('table.column.label.translationFallback')} </Typography.Text>
              {field.settings?.labelTexts?.[i18n.language as Language] ?? field.settings.placeholderTexts?.[i18n.language as Language] ?? t(`forms.label.${field.name}`, field.name)}
            </Typography.Paragraph>
          </Typography>
      ),
        children: <>
          {columnTitle}</>
      }),
      ellipsis: compactMode,
      dataIndex: ['form_data', field.name],
      width: getColumnWidth(field, columnTitle),
      fixed: lockedColumns[field.name],
    };

    if (['date', 'date-time', 'date-range', 'date-range-time'].includes(field.type)) {
      return {
        // filtered: visibleColumns?.includes(field.name) ?? true,
        ...filterProps,
        render: (value) => formatDateValue(value),
      };
    }

    if (field.settings.options?.length) {
      filterProps.filterMultiple = true;
      filterProps.filters = field.settings.options.map((v, vIdx) => ({
        key: vIdx, text: v.label[i18n.language as Language], value: v.label[i18n.language as Language] ?? v.label['en'] ?? "nothing"
      }));
      filterProps.filterMode = 'tree';
      filterProps.onFilter = (value, record) => {
        if (record.form_data && field.name in record.form_data) {
          const item = record.form_data[field.name];
          if (item === null || item === undefined) return false;

          else if (typeof item == "string") {
            return item == value + '' || item.includes(value + '');
          }
          else if (Array.isArray(item)) {
            return item.includes(value + '') || item.some(v => v.includes(value + ''));
          }
          console.info('no filter match')
          // return record.form_data[field.name].includes(value+'');
        }
        return false;
      }
    }
    // console.log(field.name, '\n', field.label)
    return {
      // filtered: visibleColumns?.includes(field.name) ?? true,
      ...filterProps,
      render: (value) => renderValue(value, `${compactMode ? 'is' : 'non'}-compact`, compactMode, field, i18n.language as Language),
    };
  });

  // const isLocked = (key: string): 'left'|'right'|undefined => {
  //   const currentTable = state.tables?.[tableName];

  //   if (currentTable.fixedColumnsRight?.includes(key)) return 'right'
  //   else if (currentTable.fixedColumnsLeft?.includes(key)) return 'left'
  //   else return undefined
  // }

  const columns = React.useMemo(() => {
    const firstColumns: ColumnsType<Responses.ResearchData> = [
      {
        key: 'form_filled',
        title: t('table.column.label.isFilled', 'filled'),
        dataIndex: 'form_filled',
        // fixed: 'left',
        width: 80,
        align: 'center',
        render: (isFilled) => (
          <Typography.Text type={isFilled ? 'success' : 'danger'}>
            {isFilled ? <CheckOutlined /> : <CloseOutlined />}
          </Typography.Text>
        )
      },
      {
        key: 'created_by',
        dataIndex: 'created_by',
        title: t('table.column.createdBy'),
        width: 200,
        // sortOrder: sortedInfo.columnKey === 'form_filled' ? sortedInfo.order : null,
        // sorter: {
        //   compare: (a, b) =>
        //     a.form_filled < b.form_filled,
        // },
        // render: (isFilled) => isFilled ? <CheckOutlined /> : <CloseOutlined />,
      },
      // {
      //   key: 'form',
      //   dataIndex: 'form_uid',
      //   title: t('table.column.createdBy', 'form id'),
      //   width: 100,
      //   // sortOrder: sortedInfo.columnKey === 'form_filled' ? sortedInfo.order : null,
      //   // sorter: {
      //   //   compare: (a, b) =>
      //   //     a.form_filled < b.form_filled,
      //   // },
      //   render: (formUID) => (
      //     <Typography.Text type={Boolean(formUID) ? 'success':'danger'}>
      //       {formUID ?? 'No form identifier set.'}
      //     </Typography.Text>
      //   ),
      // },
    ];

    const otherColumns: ColumnsType<Responses.ResearchData> = [
      {
        key: 'created_at',
        dataIndex: 'created_at',
        title: t('table.column.created'),
        width: 200,
        // fixed: isLocked('created_at'),
        sortOrder: sortedInfo.columnKey === 'created_at' ? sortedInfo.order : null,
        sorter: {
          compare: (a, b) => a.created_at - b.created_at,
        },
        render: (createdAt) => formatDate(createdAt),
      },
      {
        key: 'updated_at',
        dataIndex: 'updated_at',
        title: t('table.column.lastUpdated'),
        width: 200,
        // fixed: lockedColumns.updated_at,
        // sortOrder: sortedInfo.columnKey === 'updated_at' ? sortedInfo.order : null,
        // sorter: {
        //   compare: (a, b) =>
        //     new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
        // },
        render: (updatedAt) => formatDate(updatedAt)
      },
      {
        key: 'status',
        // fixed: isLocked('status'),
        dataIndex: 'status',
        title: t('table.column.label.status'),
        width: 100,
        filters: statusItems.map(
          (i, idx) => ({ value: i, label: i, text: i })
          // (i, idx) => ({ key:idx, text: i.label, value: i?.key})
        ),
        onFilter: (value, record) => record.status == value,
        render: (status) => {
          const color =
            status === 'draft' ? 'gold'
              : status === 'active' ? 'green'
                : status === 'deleted' ? 'red'
                  : 'cyan';
          return <Tag color={color}>{status.toUpperCase()}</Tag>;
        },
      },
      // {
      //   key: 'version',
      //   title: t('table.column.label.version'),
      //   dataIndex: 'version',
      //   align: 'center',
      //   width: 100,
      //   sorter: {
      //     compare: (a, b) => a.version - b.version,
      //     multiple: 3,
      //   },
      // },
      // {
      //   key: 'actions',
      //   title: t('table.column.action'),
      //   // dataIndex: '',
      //   align: 'center',
      //   width: 100,
      //   sorter: {
      //     compare: (a, b) => a.version - b.version,
      //     multiple: 3,
      //   },
      // }

    ];

    const extraColumns = formatColumns(fFields)

    return [
      ...firstColumns,
      ...extraColumns,
      ...otherColumns
    ];
  }, [fFields, visibleColumns, compactMode, lockedColumns]);


  const updateTableConfig = (data: App.TableMeta) => {
    dispatch({ type: ActionTypes.Update_table, payload: { tableName, data } });
    console.info({ type: ActionTypes.Update_table, tableName, data });
  };

  const loadTableConfig = () => {
    dispatch({ type: ActionTypes.Load_table, payload: { tableName } });
    console.info({ type: ActionTypes.Load_table }, tableName);
  };


  React.useEffect(() => {
    if (!state.tables[tableName]) {
      loadTableConfig();
    }
    const cleanup = get();

    return () => {
      cleanup();
    };
  }, [i18n.language]);


  React.useEffect(() => {
    if (formsData.length > 0) {
      setFFields(formsData[0].fields);
    }
  }, [formsData]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearch(e.target.value);
  };

  const clearAll = () => { };

  const handleFacultyFilterChange = (selectedValues: string[]) => {
    if (Array.isArray(selectedValues)) {
      const prev = state.tables[tableName];
      updateTableConfig({
        ...prev,
        filters: {
          ...prev.filters,
          "faculty_code": selectedValues,
        }
      })
    }
  }

  const handleStatusFilterChange = (selectedValues: string[]) => {
    if (Array.isArray(selectedValues)) {
      const prev = state.tables[tableName];
      updateTableConfig({
        ...prev,
        filters: {
          ...prev.filters,
          "status": selectedValues,
        }
      })
    }
  };

  const handleDateRangeFilterChange = ([start, end]: [string, string]) => {
    console.log('start:', start, ' to:', end)
  };

  React.useEffect(() => {
    if (!search) {
      setSearchedData(researchData);
    }
    if (researchData) {
      const newFilteredList = researchData.filter((d) =>
        JSON.stringify(d.form_data)
          .toLowerCase()
          .includes(search.toLowerCase())
      )
      setSearchedData(newFilteredList);
    }
  }, [search, researchData]);

  const columnNames = columns.map(col => ({
    key: col.key as string,
    value: col.key as string,
    label: col.title as string
  }));

  const closeFilterDrawer = () => {
    setFiltersOpen(false);
  };

  const tableFilters = [
    {
      key: 'search',
      label: `${t('table.labels.searchFilter', 'search')}`,
      children: (
        <Input.Search
          placeholder={t('table.labels.searchPlaceholder')}
          allowClear
          onSearch={(value) => handleSearch(value)}
          style={{ width: '100%' }}
        />
      )
    },
    // {
    //   key: 'creator',
    //   label: `${t('table.labels.creatorFilter', 'looja')} (${0})`,
    //   children: (
    //     <Select
    //       allowClear
    //       mode="multiple"
    //       placeholder={t('table.labels.selectCreator')}
    //       style={{ width: '100%' }}
    //       onChange={(value) => handleCreatorFilterChange(value)}
    //     >
    //       {/* Options for different creators */}
    //     </Select>
    //   )
    // },
    {
      key: 'faculty',
      label: (
        <Typography.Text strong={(state.tables[tableName]?.filters?.faculty_code! ?? []).length > 0}>
          {t('table.labels.facultyFilter', 'üksus')}
          {(state.tables[tableName]?.filters && 'faculty_code' in state.tables[tableName]?.filters) && ` (${(state.tables[tableName]?.filters?.['faculty_code'] ?? []).length})`}
        </Typography.Text>
      ),
      children: (
        <Select
          allowClear
          mode="multiple"
          placeholder={t('table.labels.selectCreator')}
          style={{ width: '100%' }}
          onChange={(value) => handleFacultyFilterChange(value)}
          value={state.tables[tableName]?.filters?.faculty_code}
        />
      )
    },
    {
      key: 'status',
      label: (
        <Typography.Text strong={(state.tables[tableName]?.filters?.status ?? []).length > 0}>
          {t('table.labels.statusFilter', 'staatus')}
          {state.tables[tableName]?.filters?.status && ` (${(state.tables[tableName]?.filters?.status ?? []).length})`}
        </Typography.Text>
      ),
      children: (
        <Select
          allowClear
          mode="multiple"
          placeholder={t('table.labels.selectStatus')}
          style={{ width: '100%' }}
          value={state.tables[tableName]?.filters?.status ?? []}
          onChange={(value) => handleStatusFilterChange(value)}
          options={statusItems.map(
            (i, idx) => ({ key: idx, text: i, value: i })
          )}
        />
      )
    },
    {
      key: 'updated_date_ranges',
      label: `${t('table.labels.dateRangeFilter', 'kuupäeva järgi')} (${0})`,
      children: (
        <Space direction="vertical" size={12}>
          <Radio.Group
            defaultValue='created'
            options={[
              { value: 'created', label: t('', 'loodud') },
              { value: 'updated', label: t('', 'uuendatud') },
            ]}
          />
          <DatePicker.RangePicker
            onChange={(dates, dateStrings) => handleDateRangeFilterChange(dateStrings)}
          />
        </Space>
      )
    },
  ];

  const filtersCount: number = Object.values(
    state.tables[tableName]?.filters ?? {}
  ).filter(i => i).length;

  const handleSharedUserSelection = (user: API.UserMeta) => {
    const userExists = sharedWithUsers.find(u => u.username === user.username);
    console.log(user, userExists)
    if (userExists) {
      message.info('Kasutaja juba valitud');
    } else {
      setSharedWithUsers((prev) => [...prev, user])
    }
  };

  const handleSharedUserRemove = (index: number) => {
    setSharedWithUsers((prev) => {
      return prev.filter((_, i) => i !== index);
    })
  };

  return (
    <>
      <PageWrapper>
        {/*
          <Row>
            <Col span={24}>
              <Typography.Title level={1} style={{marginBottom: 0}}>
                { t(`pages.nav.${(formsData[0] && formsData[0].slug) ?? 'project'}s`) }
              </Typography.Title>
            </Col>
          </Row>
        */}
        <Row justify='space-between' style={{ margin: '1rem 0' }}>
          <Col>
            <Input.Search
              placeholder={t("forms.placeholders.search")}
              value={search}
              onSearch={handleSearch}
              onChange={handleSearchChange}
            // prefix={<SearchOutlined />}
            />
          </Col>
          
        </Row>
      </PageWrapper>

      <AppTable<Responses.ResearchData>
        rowKey='uuid'
        tableName={tableName}
        tableTitle='table.title.projects.research'
        loading={isLoading}
        // dataSource={searchedData}
        dataSource={searchedData}
        extraColumns={columns}

        sticky={{ offsetHeader: 64 }}
        scroll={{ x: 1024 }}
        className='ak-table admin-table'
        rowClassName={compactMode ? ' compact-row ' : ''}
        // isFilterable={true}
        headerButtons={[
          <Badge
            key={'open-filters'}
            size="small"
            count={filtersCount}
            style={{ zIndex: 999 }}
          >
            <Button
              shape="round"
              icon={<FilterOutlined style={{ fontSize: '.9em' }} />}
              onClick={() => setFiltersOpen(true)}
              styles={{
                icon: {
                  color: filtersCount ? token.colorPrimary : 'inherit'
                }
              }}
            >
              {t('forms.btn.filters')}
            </Button>
          </Badge>

        ]}
        expandable={{
          rowExpandable: (record) => record.form_data !== null && Boolean(Object.keys(record.form_data).length),
          expandedRowRender: (record) => (
            <ResearchExpandRowContent
              key={record.uuid}
              record={record}
              onEdit={handleDataUpdate}
              onAdminEdit={handleResearchAdminChange}
              formFields={fFields}
              isAdmin={true}
            />
          ),
          // expandedRowClassName: compactMode? ' compact ' : 'non-compact',
          expandIcon: ({ expanded, onExpand, record }) => expanded
            ? <DownSquareFilled onClick={(e) => onExpand(record, e)} style={{ fontSize: '1.4rem' }} />
            : <RightSquareOutlined onClick={(e) => onExpand(record, e)} style={{ fontSize: '1.4rem' }} />
        }}
        // onChange={handleChange}
        style={{ width: '100%' }}
        isAdmin={true}
        isSelectable={false}
      />

      <Drawer
        key='filters'
        placement='right'
        open={filtersOpen}
        onClose={closeFilterDrawer}
        closable={false}
        title={
          <Space>
            <FilterTwoTone />
            {t('table.title.tableFilters')}
          </Space>
        }
        style={{ width: '100%', height: '100%' }}
        styles={{
          body: {
            position: 'relative',
            display: 'block',
            padding: 0,
            width: '100%'
          }
        }}
        footer={
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Button type='text' onClick={closeFilterDrawer}>
              {t('buttons.close')}
            </Button>
            <Button type='link' onClick={clearAll}>
              {t('forms.btn.reset')}
            </Button>
          </Space>
        }
      >
        {/* <Typography style={{ textAlign: 'center', marginTop: 6 }}>
          <Typography.Text type='warning' style={{marginRight: 6}}>
            NB!
          </Typography.Text>  
          filtrid pole veel ühendatud
          {t('table.description.tableFilters')}
        </Typography> */}

        <Collapse
          bordered={false}
          defaultActiveKey={['search', ...Object.keys(state.tables?.[tableName]?.filters ?? {})]}
          // onChange={(key: string | string[]) => console.log(key)}
          expandIconPosition='end'
          items={tableFilters}
        />

        <Flex
          vertical
          gap={'1rem'}
          justify='center'
          align='center'
          style={{
            padding: '1rem 1.4rem'
          }}
        >
          <Button
            block
            icon={<FilterFilled />}
            onClick={clearAll}
          >
            {t('table.texts.filterConfirm')}
          </Button>
          <Tooltip
            title={t('forms.btn.clearAllFilters')}
            placement='left'
          >
            <Button
              block
              type='dashed'
              icon={<ClearOutlined />}
              onClick={clearAll}
            >
              {t('forms.btn.clearAllFilters')}
            </Button>
          </Tooltip>
        </Flex>
      </Drawer>


      <Modal
        title={t(`forms.titles.${action}Article30`)}
        open={modalOpen}
        footer={null}
        // onOk={handlePost}
        // okText={t(`forms.btn.${action === 'new' ? 'create' : 'update'}`)}
        // cancelText={t('forms.btn.cancel')}
        confirmLoading={isLoading}
        onCancel={handleCancel}
        width={800}
      >
        <Typography>
          {selectedForm.current?.description}
        </Typography>

        <Divider />

        <DynamicFormModal
          formData={formsData[0] ?? { fields: [] }}
          initialValues={selectedItem.current}
          //@ts-ignore
          options={undefined}
          isAdmin={true}
          onFinish={handleResearchItemUpdate}
          onFinishFailed={handleFailSubmission}
          onCancel={handleCancel}
          facultyOptions={faculties}
          action={action}
          onDelete={handleDataDelete}
          projectOptions={[]}
          extra={
            <>
              <Button
                shape={'round'}
                type={'default'}
                icon={<SearchOutlined />}
                onClick={() => setShowLdapUserSearch(true)}
                style={{ margin: '0 1rem 1rem' }}
              >
                {t('forms.btn.searchUtUser', 'UT kasutaja otsing')}
              </Button>
              {sharedWithUsers.length > 0 ? (
                <List<LdapUserInfo | API.UserMeta>
                  itemLayout="horizontal"
                  size={'small'}
                  dataSource={sharedWithUsers}
                  renderItem={(user, userIdx) => {
                    // const isExistingUser = userExists(user.username);
                    const isExistingUser = false;
                    return (
                      <List.Item
                        extra={
                          <Button
                            shape={'round'}
                            size={'small'}
                            type={'dashed'}
                            icon={<UserDeleteOutlined />}
                            className={'user-action-button'}
                            onClick={() => handleSharedUserRemove(userIdx)}
                          >
                            {t(`forms.btn.remove`)}
                          </Button>
                        }
                      >
                        <List.Item.Meta
                          title={
                            <Space>
                              {//@ts-ignore
                                `${user.firstName || user.firstname} ${user.lastName || user.lastname}`}
                              <Tag color="processing" bordered={false}>{user.email}</Tag>
                            </Space>
                          }
                        />
                      </List.Item>
                    );
                  }}
                />
              ) : null}
            </>
          }
        />
      </Modal>

      <Modal
        title={t(`forms.titles.${action}Article30`)}
        open={adminModalOpen}
        onOk={console.log}
        // okText={t(`forms.btn.${action === 'new' ? 'create' : 'update'}`)}
        // cancelText={t('forms.btn.cancel')}
        confirmLoading={isLoading}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Typography>
          {selectedForm.current?.description}
        </Typography>
        <Divider />
        <AdminDataUpdate
          initialValues={selectedItem.current}
          isResearchForm={true}
          formOptions={formsData}
          callback={handleEntityPost}
        />
      </Modal>
      <UserSearchModalPublic
        visible={showLdapUserSearch}
        onClose={() => setShowLdapUserSearch(false)}
        //@ts-ignore
        onSelectUser={handleSharedUserSelection}
        existingUsers={sharedWithUsers}
      />

    </>
  );
};
