import {
  PlusOutlined,
  ReloadOutlined,
  NumberOutlined,
} from '@ant-design/icons';
import { ProjectForm } from '@App/components/forms/ProjectForm';
import { formatDateTime } from '@App/components/helpers';
import AppTable from '@App/components/tables/TableComponent';
import PageWrapper from '@App/components/wrappers/PageWrapper';
import { handler } from '@App/settings/ApiHandler';
import { useKeycloak } from '@App/settings/keycloak';
import { ActionTypes } from '@App/settings/reducers';
import { ApplicationState } from '@App/settings/StateManager';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Grid,
  Input,
  message,
  Modal,
  Row,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useTranslation } from 'react-i18next';

import EditButton from '../../../components/buttons/EditButton';
import { DataContext } from '../../AdminFormView';
import KC from "@App/@types/keycloakTypes";


const postProject = (kc: KC.KCType, payload: API.PayloadType) => handler<Responses.Default>({
  method: 'POST',
  path: '/v3/manage/projects/',
  payload,
}, kc);

const updateProject = (kc: KC.KCType, payload: API.PayloadType, projectKey?: string) => handler<Responses.Default>({
  method: 'PUT',
  path: `/v3/manage/projects/${projectKey}`,
  payload,
}, kc);

const tableName = 'projectsTable';

export const AdminProjects: React.FC = () => {
  const { state, dispatch } = React.useContext(ApplicationState);
  const [action, setAction] = React.useState<API.ActionType>('new');
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const { projects, isLoading, setLoading, doRefresh } = React.useContext(DataContext);
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { md } = Grid.useBreakpoint();
  const [form] = Form.useForm();

  const handleClose = () => setModalOpen(false);

  const handleSubmit = (values: object) => {
    setLoading(true)
    const payload = { action, values };

    if (action == 'new') {
      postProject(keycloak!, payload)
        .then((res) => {
          if (res.success) {
            message.success(t(`forms.success.${res.success}`));
            handleClose();
            doRefresh();
          } else if (res.error) {
            message.error(t(`forms.error.${res.error}`));
          }

        })
        .finally(() => setLoading(false))
    } else {
      const itemSlug: string = ('slug' in values && values.slug) ? values.slug as string : '';
      updateProject(keycloak!, payload, itemSlug)
        .then((res) => {
          if (res.success) {
            //@ts-ignore
            message.success(t(`forms.success.${res.success}`, res.message));
            handleClose();
            doRefresh();
          } else if (res.error) {
            //@ts-ignore
            message.error(t(`forms.error.${res.error}`, res.message));
          }
        })
        .finally(() => setLoading(false))

    }
  };

  const deleteProjectItem = (itemKey: string) => {
    setLoading(true)
    updateProject(keycloak!, { action }, itemKey + '/delete')
      .then((res) => {
        if (res.success) {
          //@ts-ignore
          message.success(t(`forms.${res.status || res.variant || 'error'}.${res.success}`, res.message));
          handleClose();
          doRefresh();
        } else if (res.error) {
          //@ts-ignore
          message.error(t(`forms.${res.status || res.variant || 'error'}.${res.error}`, res.message));
        }


      })
      .finally(() => setLoading(false))

  };

  const handleNewProject = () => {
    form.resetFields();
    setAction('new');
    setModalOpen(true);
  };

  const handleDataUpdate = (slug: string) => {
    const selectedDataValues = projects.find(i => i.slug == slug);

    if (selectedDataValues) {
      form.setFieldsValue(selectedDataValues)
      setAction('update');
      setModalOpen(true);
    }
  };

  const printDates = (_: any, { updatedAt, createdAt }: API.ProjectNames) => {
    if (updatedAt) {
      return formatDateTime(updatedAt, true)
    }
    return formatDateTime(createdAt)
  }

  const columns: ColumnsType<API.ProjectNames> = [
    {
      key: 'index',
      title: <NumberOutlined />,
      align: 'center',
      width: 50,
      render: (_, r, i) => (
        <Avatar size='small'>{i + 1}</Avatar>
      )
    },
    {
      key: 'name',
      title: t('table.column.label.projectName'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, record) => (
        //@ts-ignore
        <Tooltip title={record.key}>
          {record.name}
        </Tooltip>
      )
    },
    {
      key: 'version',
      title: t('table.column.label.createdAt'),
      dataIndex: 'createdAt',
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: printDates
    },
    {
      key: 'status',
      title: t('table.column.label.status'),
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status, record) => {
        const color =
          status === 'draft' ? 'gold'
            : status === 'active' ? 'green'
              : status === 'confirmed' ? 'green'
                : status === 'deleted' ? 'red'
                  : 'blue';
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
      width: 120,
    },
    {
      key: 'action',
      title: t('table.column.label.action'),
      dataIndex: 'key',
      align: 'center',
      render: (itemKey, record: API.ProjectNames) => (
        <Space size='small' wrap>
          <EditButton
            size={'small'}
            type={'text'}
            shape={md ? 'round' : 'circle'}
            iconOnly={md}
            record={record}
            onClick={() => handleDataUpdate(record.slug)}
          />
          <Button
            danger
            type={'text'}
            size={'small'}
            shape={md ? 'round' : 'circle'}
            onClick={() => deleteProjectItem(itemKey || record.slug)}
          >
            {t('forms.btn.delete')}
          </Button>
          <Button
            type={'dashed'}
            size={'small'}
            shape={md ? 'round' : 'circle'}
            onClick={() => console.info(record)}
          >
            {'log'}
          </Button>
          {/*<DeleteButton
            shape={md ? 'round' : 'circle'}
            path='forms'
            record={record}
            item='project'
          />*/}
        </Space>
      ),
    },
  ];

  const loadTableConfig = () => {
    dispatch({ type: ActionTypes.Load_table, payload: { tableName } });
    console.info({ type: ActionTypes.Load_table }, tableName);
  };

  React.useEffect(() => {
    if (!state.tables[tableName]) {
      loadTableConfig();
    };
  }, []);

  return (
    <>
      <PageWrapper>
        <Row justify='space-between' align='bottom' style={{ margin: '1rem 0' }}>
          <Col>
            <Input.Search
              placeholder={t('forms.placeholders.search')}
            // value={search}
            // onSearch={handleSearch}
            // onChange={handleSearchChange}
            />
          </Col>
          <Button.Group>
            <Button
              // type='primary'
              shape='round'
              icon={<ReloadOutlined spin={isLoading} />}
              onClick={() => doRefresh()}
            >
              {t('buttons.refresh')}
            </Button>
          </Button.Group>
        </Row>
      </PageWrapper>

      <AppTable<API.ProjectNames>
        className='ak-table admin-table'
        rowKey='slug'
        tableName={tableName}
        tableTitle='table.title.projects.manageProject'
        extraColumns={columns}
        dataSource={projects ?? []}
        loading={isLoading}
        counter={projects.length}
        isAdmin={true}
        isSelectable={false}
        headerButtons={[
          <Button
            key='new-project'
            type='primary'
            shape='round'
            icon={<PlusOutlined />}
            onClick={handleNewProject}
          >
            {t('forms.btn.add')}
          </Button>

        ]}
      />

      <Modal
        title={t(`forms.titles.${action}Article30`)}
        open={modalOpen}
        footer={null}
        // onOk={handlePost}
        // okText={t(`forms.btn.${action === 'new' ? 'create' : 'update'}`)}
        // cancelText={t('forms.btn.cancel')}
        confirmLoading={isLoading}
        onCancel={handleClose}
        width={800}
      >
        <Divider />
        <ProjectForm
          form={form}
          action={action}
          onFinish={handleSubmit}
          handleClose={handleClose}
        />

      </Modal>
    </>
  );
};
